body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.signupform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.dropdown {
    padding: 10px; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    font-size: 16px; 
    cursor: pointer;
    width:400px;
}

.popup {
  /* position: fixed;
  top: 10%;
  left: 50%; */
  /* transform: translateX(-50%); */
  /* padding: 10px 20px; */
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
}
